import { Component, Input, OnInit } from '@angular/core';
import { CompensationTypes } from '@app/constants/compensation-types';
import { Currency } from '@app/models/common/currency.model';
import { CurrencyService } from '@app/services';
import { BaseForm } from '@forms/base.form';
import { Employee } from '@models/employee/employee.model';
import { Salary } from '@models/employee/salary.model';

@Component({
    selector: 'app-form-employees-create-terminated-payroll',
    templateUrl: './create-terminated-payroll.template.html',
})
export class CreateTerminatedPayrollForm extends BaseForm implements OnInit {
    @Input() salary: Salary;
    @Input() employee: Employee;
    @Input() canDoPayroll = false;
    compensationTypes = CompensationTypes;
    currencies: Currency[] = [];

    constructor(private currencyService: CurrencyService) {
        super();
    }

    async ngOnInit(): Promise<void> {
        this.currencies = await this.currencyService.getAllCurrencies();
    }

    isRequired(): boolean {
        return (
            this.compensationTypes.hasOwnProperty(this.salary.frequency) ||
            !!this.salary.rate ||
            !!this.salary.hoursPerWeek ||
            !!this.salary.effectiveAt
        );
    }
}
