<form #form="ngForm">
    <ng-container *ngIf="employeeBenefitPlan">
        <div class="app-grid">
            @if (showBenefitFields) {
                @if (benefitPlans) {
                    <mat-form-field class="columns-12">
                        <mat-label appRemoveAriaOwns>{{ 'benefits.benefitsForm.planClass' | translate }}</mat-label>
                        <mat-select name="plan_id" [(ngModel)]="employeeBenefitPlan.benefitPlanId">
                            <mat-option [value]="null">{{ 'benefits.benefitsForm.none' | translate }}</mat-option>
                            <mat-option *ngFor="let plan of benefitPlans" [value]="plan.id"
                                >{{ plan.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                }

                <mat-form-field class="columns-12">
                    <mat-label appRemoveAriaOwns>{{ 'benefits.benefitsForm.eligible' | translate }}</mat-label>
                    <mat-select name="eligible" [(ngModel)]="employeeBenefitPlan.isEligible">
                        <mat-option value="">{{ 'benefits.benefitsForm.selectAnOption' | translate }}</mat-option>
                        <mat-option [value]="true">{{ 'benefits.benefitsForm.yes' | translate }}</mat-option>
                        <mat-option [value]="false">{{ 'benefits.benefitsForm.no' | translate }}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="columns-12">
                    <mat-label appRemoveAriaOwns>{{ 'benefits.benefitsForm.enrolled' | translate }}</mat-label>
                    <mat-select name="enrolled" [(ngModel)]="employeeBenefitPlan.isEnrolled">
                        <mat-option value="">{{ 'benefits.benefitsForm.selectAnOption' | translate }}</mat-option>
                        <mat-option [value]="true">{{ 'benefits.benefitsForm.yes' | translate }}</mat-option>
                        <mat-option [value]="false">{{ 'benefits.benefitsForm.no' | translate }}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="columns-12">
                    <mat-label appRemoveAriaOwns>{{
                        'benefits.benefitsForm.waitingPeriodWaived' | translate
                    }}</mat-label>
                    <mat-select name="waiting_period_waived" [(ngModel)]="employeeBenefitPlan.isWaitingPeriodWaved">
                        <mat-option value="">{{ 'benefits.benefitsForm.selectAnOption' | translate }}</mat-option>
                        <mat-option [value]="true">{{ 'benefits.benefitsForm.yes' | translate }}</mat-option>
                        <mat-option [value]="false">{{ 'benefits.benefitsForm.no' | translate }}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="columns-12">
                    <mat-label appRemoveAriaOwns>{{ 'benefits.benefitsForm.dependentStatus' | translate }}</mat-label>
                    <mat-select name="opted_as_type" [(ngModel)]="employeeBenefitPlan.isFamily">
                        <mat-option [value]="false">{{ 'benefits.benefitsForm.single' | translate }}</mat-option>
                        <mat-option [value]="true">{{ 'benefits.benefitsForm.family' | translate }}</mat-option>
                    </mat-select>
                </mat-form-field>
            }

            <h2 class="ui left floated header">{{ 'benefits.benefitsForm.benefitsCard' | translate }}</h2>

            <mat-form-field class="columns-12">
                <mat-label>{{ 'benefits.benefitsForm.cardTitle1' | translate }}</mat-label>
                <input
                    matInput
                    type="text"
                    name="card_title_1"
                    [(ngModel)]="employeeBenefitPlan.cardTitlePrimary"
                    placeholder="{{ 'benefits.benefitsForm.egCardTitle1' | translate }}"
                />
            </mat-form-field>
            <mat-form-field class="columns-12">
                <mat-label>{{ 'benefits.benefitsForm.cardValue1' | translate }}</mat-label>
                <input
                    matInput
                    type="text"
                    name="card_value_1"
                    [(ngModel)]="employeeBenefitPlan.cardValuePrimary"
                    placeholder="{{ 'benefits.benefitsForm.egCardValue1' | translate }}"
                />
            </mat-form-field>
            <mat-form-field class="columns-12">
                <mat-label>{{ 'benefits.benefitsForm.cardTitle2' | translate }}</mat-label>
                <input
                    matInput
                    type="text"
                    name="card_title_2"
                    [(ngModel)]="employeeBenefitPlan.cardTitleSecondary"
                    placeholder="{{ 'benefits.benefitsForm.egCardTitle2' | translate }}"
                />
            </mat-form-field>
            <mat-form-field class="columns-12">
                <mat-label>{{ 'benefits.benefitsForm.cardValue2' | translate }}</mat-label>
                <input
                    matInput
                    type="text"
                    name="card_value_2"
                    [(ngModel)]="employeeBenefitPlan.cardValueSecondary"
                    placeholder="{{ 'benefits.benefitsForm.egCardValue2' | translate }}"
                />
            </mat-form-field>
            <mat-form-field class="columns-12">
                <mat-label>{{ 'benefits.benefitsForm.cardTitle3' | translate }}</mat-label>
                <input
                    matInput
                    type="text"
                    name="card_title_3"
                    [(ngModel)]="employeeBenefitPlan.cardTitleTertiary"
                    placeholder="{{ 'benefits.benefitsForm.egCardTitle3' | translate }}"
                />
            </mat-form-field>
            <mat-form-field class="columns-12">
                <mat-label>{{ 'benefits.benefitsForm.cardValue3' | translate }}</mat-label>
                <input
                    matInput
                    type="text"
                    name="card_value_3"
                    [(ngModel)]="employeeBenefitPlan.cardValueTertiary"
                    placeholder="{{ 'benefits.benefitsForm.egCardValue3' | translate }}"
                />
            </mat-form-field>

            @if (showCertificateNumber) {
                <mat-form-field class="columns-12">
                    <mat-label>{{ 'benefits.benefitsForm.certificateNumber' | translate }}</mat-label>
                    <input
                        matInput
                        type="text"
                        name="certificate_number"
                        [(ngModel)]="employeeBenefitPlan.certificateNumber"
                    />
                </mat-form-field>
            }
        </div>
    </ng-container>
</form>
