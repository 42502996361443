import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { DataFieldTypes } from '@app/enums/data-field-types.enum';
import { ValidatedDataFieldGroup } from '@app/modules/employees/interfaces/validated-data-field-group.interface';
import { sizeBasePixels } from '@app/styles/theme';
import { BaseForm } from '@forms/base.form';
import { DataValue } from '@models/employee/data-value.model';
import { Employee } from '@models/employee/employee.model';
import { DataField } from '@models/settings/data-field.model';
import { DefaultGroupFieldNamesEnum } from '@settings/enums/default-group-field-names.enum';

@Component({
    selector: 'app-form-data-fields',
    templateUrl: './data-fields.form.html',
    styles: [
        `
            .input-label {
                text-align: left;
                margin-bottom: -${sizeBasePixels * 2}px;
                color: var(--grey-800);
            }
        `,
    ],
})
export class DataFieldsForm extends BaseForm implements AfterViewInit, OnChanges {
    @ViewChild('form', { static: true }) form: NgForm;

    @Input() employee: Employee;
    @Input() dataFieldGroup: string;
    @Input() isDefaultGroup = false;
    @Input() dataFields: DataField[];
    @Input() dataValues: DataValue[];
    @Input() columnClass = 'columns-12';
    @Input() emitFormValidation = false;
    @Output() dataValuesChange: EventEmitter<DataValue[]> = new EventEmitter<DataValue[]>();
    @Output() formValidationCheckOnDataFieldGroup: EventEmitter<any> = new EventEmitter<any>();
    errorStateMatcher = new ErrorStateMatcher();
    dataFieldTypes = DataFieldTypes;
    defaultGroupFieldNames = DefaultGroupFieldNamesEnum;

    filteredDataFields: DataField[];
    isLoading = true;
    //This will be used to store any data fields that don't have values when page is loaded
    emptyDataFields: DataField[];

    ngAfterViewInit(): void {
        this.prepareForm();
        this.initializeFormStateWhenRequiredFields();
    }

    ngOnChanges(changes: any): void {
        if (!this.emptyDataFields?.length) {
            this.setFieldsWithEmptyData();
        }
        if (changes.dataFieldGroup || changes.dataValues) {
            this.prepareForm();
        }
    }

    onDataValueChange(): void {
        this.dataValuesChange.emit(this.dataValues);
        if (this.emitFormValidation) {
            const validatedDataFieldGroup: ValidatedDataFieldGroup = {
                dataFieldGroupName: this.dataFieldGroup,
                valid: this.form.valid,
            };

            this.formValidationCheckOnDataFieldGroup.emit(validatedDataFieldGroup);
        }
    }

    /*
     * Used by the dropdown loop to track changes in the option list, we
     * don't want ngFor to handle this for us since we build the list dynamically
     * */
    trackByFunction(_index: number, item: any): null | any {
        if (!item) {
            return null;
        }

        return item.value;
    }

    prepareForm(): void {
        if (this.dataFieldGroup !== undefined) {
            this.filteredDataFields = this.dataFields.filter((field) => {
                if (field.dataFieldGroup.isCustom) {
                    return (
                        field.dataFieldGroup.name === this.dataFieldGroup &&
                        field.dataFieldGroup.isDefault === this.isDefaultGroup
                    );
                }

                return (
                    this.defaultGroupFieldNames[field.dataFieldGroup.defaultGroupType] === this.dataFieldGroup &&
                    field.dataFieldGroup.isDefault === this.isDefaultGroup
                );
            });

            this.getDataValuesForFields();
            if (this.form && this.emitFormValidation) {
                setTimeout(() => {
                    const validatedDataFieldGroup: ValidatedDataFieldGroup = {
                        dataFieldGroupName: this.dataFieldGroup,
                        valid: this.form.valid,
                    };

                    this.formValidationCheckOnDataFieldGroup.emit(validatedDataFieldGroup);
                });
            }
        }
    }

    getDataValueIndexForField(dataFieldId: number): number | null {
        if (!this.dataValues || !this.dataValues.length) {
            return -1;
        }

        return this.dataValues.findIndex((dataValue) => dataValue.dataFieldId === dataFieldId);
    }

    /**
     * Load the data values that this role has access to for the specific employee
     */
    getDataValuesForFields(): void {
        this.createEmptyValuesForNewFields();
        this.isLoading = false;
    }

    private isDataFieldValueEmpty(dataField: DataField): boolean {
        const isEmpty = this.dataValues
            ?.filter((dataValue) => dataValue.dataFieldId === dataField.id)
            .some((dataValue) => dataValue.value === null || !dataValue.value.length);

        return isEmpty;
    }

    private setFieldsWithEmptyData(): void {
        this.emptyDataFields = this.dataFields?.filter((dataField) => this.isDataFieldValueEmpty(dataField));
    }

    /**
     * Cycle the data fields and ensure they have value models
     */
    private createEmptyValuesForNewFields(): void {
        const newValues = this.filteredDataFields
            .filter((dataField) => this.getDataValueIndexForField(dataField.id) === -1)
            .map((dataField) => {
                return new DataValue({
                    employeeId: this.employee.id,
                    dataFieldId: dataField.id,
                    value: null,
                });
            });

        this.dataValues = [...this.dataValues, ...newValues];
    }

    private initializeFormStateWhenRequiredFields(): void {
        // When editing these fields under profile, filteredDataFields will be undefined
        if (!this.filteredDataFields?.length) {
            return;
        }

        // Only set valid status to false when there's empty, required fields
        const hasEmptyRequiredField = this.emptyDataFields.some((dataField) => dataField.isRequired);

        if (!hasEmptyRequiredField) {
            return;
        }

        this.formValidationCheckOnDataFieldGroup.emit({
            dataFieldGroupName: this.dataFieldGroup,
            valid: false,
        });
    }
}
